import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import type { Query } from "Types/GraphQL"
import Layout from "Layouts/layout"
import SEO from "Components/seo"
import Markdown from "Styles/markdown"
import { rhythm } from "Styles/typography"

const Tenets = () => {
  const data = useStaticQuery<Query>(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/tenets/" } }) {
        edges {
          node {
            html
          }
        }
      }
    }
  `)

  const markdown = data.allMarkdownRemark.edges[0].node.html

  return (
    <Layout>
      <main>
        <article>
          <OuterWrapper>
            <InnerWrapper>
            <Container
        dangerouslySetInnerHTML={{ __html: markdown ?? "" }}
        rhythm={rhythm}
      ></Container>

            </InnerWrapper>
          </OuterWrapper>
        </article>
      </main>
    </Layout>
  )
}

const Container = styled(Markdown).attrs({
  as: "main",
})`
width: var(--post-width);
margin: 0 auto;
padding: 3rem 12rem 8rem 12rem;
border-radius: 12px;
@media (max-width: ${({ theme }) => theme.device.md}) {
  width: 95%;
  padding: 2rem;
}
@media (max-width: ${({ theme }) => theme.device.sm}) {
  width: 100%
  padding: 2rem;
}
  }

  h1 {
    margin-bottom: 2rem;
  }

  h2 {
    margin-top: var(--sizing-lg);

    @media (max-width: ${({ theme }) => theme.device.sm}) {
      font-size: 1.75rem;
    }
  }

  h3 {
    @media (max-width: ${({ theme }) => theme.device.sm}) {
      font-size: 1.25rem;
    }
  }
`
const OuterWrapper = styled.div`
background-color: #d4e2fb;
background-image: linear-gradient(315deg, #d4e2fb 0%, #fdd7f2 100%);
  @media (max-width: ${({ theme }) => theme.device.sm}) {
  }
`

const InnerWrapper = styled.div`
  width: var(--post-width);
  margin: 0 auto;
  border-radius: 12px;
  @media (max-width: ${({ theme }) => theme.device.md}) {
    width: 95%;
    padding: 2rem;
  }
  @media (max-width: ${({ theme }) => theme.device.sm}) {
    width: 100%;
    padding: .25rem;
  }
`

export default Tenets
